.localizacao {
  color: #fff;

  input {
    border-bottom: 1px solid #fff;
    color: #ccc;
    width: 100%;
  }

  .notice {
    font-size: 0.8rem;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }

  .use {
    color: #fff;
    text-decoration: underline;
    display: block;
    margin: 1rem 0 0;
    font-size: 1.2rem;
  }

  .legenda {
    margin-top: .5rem;
    font-size: 1.2rem;
  }

  @media (max-width: 500px) {
    .use {
      font-size: 1rem;
    }
    .legenda {
      font-size: 1rem;
    }
  }
}

.video {
  display: grid;
  grid-template-rows: 8% 1fr 22%;
  height: 97vh;
  max-height: 900px;
  overflow: hidden;

  z-index: 10;
  position: relative;

  @media (orientation: portrait) {
    grid-template-rows: 10vh 1fr 10vh;
    //top: 60px !important;
    max-height: calc(100vh - 60px) !important;
  }

  &.step-0 {
    grid-template-columns: 35vw 1fr 35vw;
  }

  &.step-1 {
    grid-template-rows: 20% 1fr 20%;
    grid-template-columns: 37vw 1fr 37vw;
  }

  &.step-3 {
    grid-template-rows: 30% 1fr 30%;
    grid-template-columns: 37vw 1fr 37vw;
  }

  &.step-2,
  &.step-4 {
    grid-template-rows: 26% 1fr 25%;
    grid-template-columns: 36vw 1fr 36vw;
  }


  @media (orientation: landscape) {
    @media (max-width: 1300px) {
      grid-template-rows: 3vh 1fr 13vh;
      height: 88vh;

      &.step-0 {
        grid-template-columns: 32vw 1fr 32vw;
      }
    }
  }


  @media (orientation: portrait) {
    &.step-0,
    &.step-1,
    &.step-2,
    &.step-3,
    &.step-4 {
      grid-template-columns: 10vw 1fr 10vw;
      grid-template-rows: 8vh 61vh 20vh;
      height: fit-content !important;
    }

    @media (min-height: 700px) {
      &.step-0,
      &.step-1,
      &.step-2,
      &.step-3,
      &.step-4 {
        grid-template-rows: 8vh 61vh 13vh;
      }
    }
    @media (min-width: 750px) {
      &.step-0,
      &.step-1,
      &.step-2,
      &.step-3,
      &.step-4 {
        grid-template-columns: 20vw 1fr 20vw;
        grid-template-rows: 16vh 1fr 16vh;
      }
    }
  }

  h2 {
    display: none;
  }

  p {
    color: #fff;
    font-size: 1.3rem;
    margin-bottom: 1.3rem;

    @media (max-width: 500px) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }

  svg {
    margin: 0 auto;
    max-width: 50%;
  }

  * {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  .arraste {
    grid-column: 2/2;
    grid-row: 3/3;
    z-index: 10;
    display: block;

    margin-top: 20px;
    text-align: center;

    .cta {
      color: #fff;
      line-height: 3rem;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
    }

    transition: opacity 0.3s, visibility 0.3s;
  }

  .arraste .avance {
    display: block;
  }

  .arraste .voltar {
    display: none;
  }

  .arraste .arrastar {
    display: none;
  }

  .arraste .voltar > svg {
    transform: scaleX(-1);
  }

  &.step-4 {
    .arraste .avance {
      display: none;
    }

    .arraste .voltar {
      display: block;
    }

    .arraste .arrastar {
      display: block;
    }
  }

  .box {
    grid-column: 2/2;
    grid-row: 2/2;
    z-index: 10;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2rem;

    border-radius: 10px;

    transition: opacity 0.3s, visibility 0.3s;

    background-image: url("../image/bgbox.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    @media (orientation: portrait) {
      max-height: 60vh;
    }
  }

  &.step-1 {
    .box {
      @media (orientation: portrait) {
        max-height: 43vh;
      }
    }
  }

  &.step-3 {
    .box {
      @media (orientation: portrait) {
        max-height: 26vh;
      }
    }
  }

  &.step-2,
  &.step-4 {
    .box {
      @media (orientation: portrait) {
        max-height: 35vh;
      }
    }
  }

  .tour {
    padding: 10px 30px;
    display: none;
    border-radius: 10px;
    width: 100%;

    img.logo {
      width: 40%;
      margin: 0 auto 2rem;
    }

    @media (orientation: portrait) {
      padding: 0 20px;
    }
  }

  .tour-1,
  .tour-2 {
    z-index: 999;
    color: #FFF;
  }

  &.step-0 .tour-0 {
    display: flex;
    flex-direction: column;
  }

  &.step-1 .tour-1 {
    display: flex;
    flex-direction: column;
  }

  &.step-2 .tour-2 {
    display: flex;
    flex-direction: column;
  }

  &.step-3 .tour-3 {
    display: flex;
    flex-direction: column;
  }

  &.step-4 .tour-4 {
    display: flex;
    flex-direction: column;
  }

  .video-background {
    width: 100vw;
    grid-column: 1/4;
    grid-row: 1/3;
  }

  #container {
    display: none;
  }
}

/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  width: 100%;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 50px;
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: 0;
  box-shadow: none;
  outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  right: 8px;
  top: 7px;
  display: none;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
}

/* Collapsed */
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 6px 12px;
  color: #404040;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #404040;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: #757575;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  line-height: 1;
  background-color: #000;
  //outline: 1px solid #ccc;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
  margin-bottom: 0;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-geolocate {
  width: 22px;
  height: 22px;
  margin-top: 6px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: block;
  float: left;
  padding: 6px 12px;
  padding-bottom: 9px;
  font-size: 13px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
  color: #909090;
}

.mapboxgl-ctrl-geocoder--powered-by a:not(:hover) {
  text-decoration: none;
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder {
    font-size: 15px;
    line-height: 20px;
  }
  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-geolocate {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    left: 7px;
    width: 20px;
    height: 20px;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: #909090;
    padding: 6px 12px;
    font-size: 16px;
    text-align: center;
  }

  .mapboxgl-ctrl-geocoder--powered-by {
    font-size: 11px !important;
  }
}

#template {
  display: none;
}

  .pin-spacer {
    height: fit-content !important;
    padding: 0 !important;
  }