#dimensions {
}

.infografico {
    display: grid;
    grid-template-columns: 5vw 1fr 5vw;
    grid-template-rows: 5% 1fr 5%;
    position: relative;
    z-index: 10;
    background: #FFFFFF;
    margin-bottom: 5vh;

    height: 100vh;

    @media (orientation: portrait) {
        grid-template-rows: 5vh 1fr 5vh;
        margin-bottom: 0;
    }

    .intra {
        grid-column: 2/2;
        grid-row: 2/2;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (orientation: portrait) {
            @media (min-width: 750px) {
                gap: 3rem;

                h3 {
                    font-size: 7rem;
                    line-height: 6rem;
                }
            }
        }
    }

    .graph {
        position: relative;
        margin: auto;
        width: 100% !important;
        height: 55%;

        canvas {
            margin: 0 auto;
            width: 100%;
            height: 100%;
        }

        @media (orientation: portrait) {
            overflow-x: scroll;
        }
    }

    .filtros {
        color: var(--color-light);

        display: grid;
        grid-template-columns: 1fr 10rem;
        grid-template-rows: 35px 1fr;

        .mobtitle {
            display: none;
        }

        .clear {
            grid-column: 2;
            text-align: center;
            text-transform: uppercase;
            font-size: 0.8rem;
            line-height: 2.8rem;
            background: var(--color-black);
            border-radius: 10px 10px 0 0;
        }

        @media (orientation: portrait) {
            .mobtitle {
                display: block;
                background: var(--color-black);
                border-radius: 10px 0 0 10px;
                font-size: 0.8rem;
                line-height: 3rem;
                padding-left: 1.5rem;
                text-transform: uppercase;

            }

            .clear {
                border-radius: 0 10px 10px 0;
                line-height: 3rem;
            }

            &.open {
                .mobtitle {
                    border-radius: 10px 0 0 0;
                }
                .clear {
                    border-radius: 0 10px 0 0;
                }
                form {
                    display: flex;
                    flex-direction: column;
                }
            }

        }

        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            background: var(--color-black);
            border-radius: 10px 0 10px 10px;
            padding-bottom: 1rem;

            grid-column: 1/3;
            grid-row: 2;

            @media (orientation: portrait) {
                border-radius: 0 0 10px 10px;
                display: none;
            }

            fieldset {
                max-width: 20%;
                min-width: 65px;
                padding-top: 2rem;

                &:last-child {
                    padding-top: 0.5rem;
                    max-width: 75px;

                    .wrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 75%;

                        .button {
                            background-color: var(--color-orange);
                            text-transform: uppercase;
                            font-weight: 900;
                            font-size: 0.7rem;
                            padding: 0.3rem;
                            box-sizing: border-box;
                            border-radius: 5px;
                            border: 1px solid #fff;
                            display: block;
                            text-align: center;
                        }
                    }

                    @media (orientation: portrait) {
                       max-width: none;
                    }
                }


                h5 {
                    font-family: var(--font-open);
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    padding-bottom: 0.5rem;
                }

                .scrollbox {
                    max-height: 145px;
                    overflow-y: auto;
                    border-top: 1px solid #666;
                    padding-top: 1rem;

                    scrollbar-color: #666 #000;

                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                        background-color: #000;
                    }

                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #F5F5F5;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #666;
                    }

                    .rangelabel {
                        display: block;
                    }

                    .legend {
                        display: flex;
                        justify-content: space-between;

                        .current {
                            color: var(--color-orange);
                            font-weight: 900;
                        }
                    }

                    input[type=range] {
                        -webkit-appearance: none;
                        margin: 10px 0;
                        width: 100%;
                    }
                    input[type=range]:focus {
                        outline: none;
                    }
                    input[type=range]::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 3px;
                        cursor: pointer;
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                        background: #FFFFFF;
                        border-radius: 25px;
                        border: 0px solid #000101;
                    }
                    input[type=range]::-webkit-slider-thumb {
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                        border: 0px solid #000000;
                        border-radius: 5px;
                        height: 18px;
                        width: 18px;
                        background: var(--color-orange);
                        cursor: pointer;
                        -webkit-appearance: none;
                        margin-top: -7px;
                    }
                    input[type=range]:focus::-webkit-slider-runnable-track {
                        background: #FFFFFF;
                    }
                    input[type=range]::-moz-range-track {
                        width: 100%;
                        height: 3px;
                        cursor: pointer;
                        animate: 0.2s;
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                        background: #FFFFFF;
                        border-radius: 25px;
                        border: 0px solid #000101;
                    }
                    input[type=range]::-moz-range-thumb {
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                        border: 0px solid #000000;
                        height: 18px;
                        width: 18px;
                        border-radius: 7px;
                        background: var(--color-orange);
                        cursor: pointer;
                    }
                    input[type=range]::-ms-track {
                        width: 100%;
                        height: 12.8px;
                        cursor: pointer;
                        animate: 0.2s;
                        background: transparent;
                        border-color: transparent;
                        border-width: 39px 0;
                        color: transparent;
                    }
                    input[type=range]::-ms-fill-lower {
                        background: #FFFFFF;
                        border: 0px solid #000101;
                        border-radius: 50px;
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                    }
                    input[type=range]::-ms-fill-upper {
                        background: #FFFFFF;
                        border: 0px solid #000101;
                        border-radius: 50px;
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                    }
                    input[type=range]::-ms-thumb {
                        box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
                        border: 0px solid #000000;
                        height: 18px;
                        width: 18px;
                        border-radius: 7px;
                        background: var(--color-orange);
                        cursor: pointer;
                    }
                    input[type=range]:focus::-ms-fill-lower {
                        background: #FFFFFF;
                    }
                    input[type=range]:focus::-ms-fill-upper {
                        background: #FFFFFF;
                    }

                    body {
                        padding: 30px;
                    }
                }

                .item {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                    gap: 0.5rem;

                    input[type="checkbox"] {
                        appearance: none;
                        background: #000;
                        border: 1px solid #666;
                        border-radius: 5px;
                        height: 1.2rem;
                        width: 1.2rem;
                        transform: translateY(2px);
                    }

                    input[type="checkbox"]:checked {
                        background: var(--color-orange);
                    }
                }

                @media (orientation: portrait) {
                        max-width: none;
                        min-width: none;
                        padding: 2rem;
                }
            }

            .border {
                background: linear-gradient(0deg, #666 73%, #000 73%);
                width: 1px;
            }
        }
    }

    h3 {
        grid-column: 2/2;
        grid-row: 2/2;
        font-size: 6rem;
        line-height: 4rem;

        @media (max-width: 500px) {
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    h4 {
        grid-column: 2/2;
        grid-row: 2/2;
        margin: 0;
    }

    canvas {
        grid-column: 2/2;
        grid-row: 2/2;
    }

}

.flipswitch {
    position: relative;
    padding: 1rem 0;
    border-top: 1px solid #666;

    @media (orientation: portrait) {
       padding: 1rem 10rem;
    }
}
.flipswitch input[type=checkbox] {
    display: none;
}
.flipswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 3px;
   // width: 70px;
}
.flipswitch-inner {
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.flipswitch-inner:before, .flipswitch-inner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 18px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}
.flipswitch-inner:before {
    content: "";
    padding-left: 12px;
    background-color: var(--color-orange);
    color: #FFFFFF;
}
.flipswitch-inner:after {
    content: "";
    padding-right: 12px;
    color: #888888;
    text-align: right;
    
    @media (orientation: portrait) {
        background-color: #000;
    }
}
.flipswitch-switch {
    width: 35px;
    height: 20px;
    margin: -2px;
    background: #FFFFFF;
    border: 1px solid #999999;
    border-radius: 3px;
    position: absolute;
    top: 1.2rem;
    right: 3.1rem;
    transition: all 0.3s ease-in 0s;

    @media (orientation: portrait) {
        right: inherit;
        left: 10.3rem;
        top: 1.25rem;
    }
}
.operacao:checked + .flipswitch-label .flipswitch-inner {
    margin-left: 0;
}
.operacao:checked + .flipswitch-label .flipswitch-switch {
    right: 0.3rem;
    top: 1.2rem;

    @media (orientation: portrait) {
        left: inherit;
        right: 10.3rem;
        top: 1.25rem;
    }
}

#tooltip {
    z-index: 99;
}

.tooltip-content {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.2rem;

    .title {
        font-family: var(--font-open);
        font-weight: 900;
        text-transform: uppercase;
        border-bottom: 3px solid #000;
        padding-bottom: 0.3rem;
        margin-bottom: 1rem;
    }

    .line {
        font-family: var(--font-open);
        line-height: 1.5rem;
        .name {
            font-weight: 900;
        }
        &.link {
            margin-top: 1rem;
            color: var(--color-orange);
            font-weight: 900;
        }

    }

    .saibamais {
        display: block;
        margin-top: 1rem;
    }
}

.canvasjs-chart-credit {
    display: none;
}

#chart {
    max-width: 100vw;
    width: 100%;
    height: 100%;
    max-height: 90vh;
    margin: 0 1vw;

    @media (orientation: portrait) {
        max-width: none;
        width: 200%;
    }
}