@import "./variables.scss";
@import "./fonts.scss";
@import "./grid.scss";
@import "./images.scss";

@import "./top.scss";
@import "./video.scss";
@import "./menu.scss";

@import "./explica.scss";
@import "./chacinas.scss";
@import "./infografico.scss";
@import "./sobre.scss";
@import "./footer.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;